import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Card, Col, List, Row, Typography } from 'antd'
import { ComplianceItemsContainer } from './complianceLanding.style'

const ComplianceLandingSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        COMPLIANCE_PAGE {
          headerText
          complianceItems {
            url
            text
          }
        }
      }
    }
  `)

  const compliancePageData = Data.dataJson.COMPLIANCE_PAGE[0]

  return (
    <ComplianceItemsContainer>
      <Row style={{ width: '100%' }}>
        <Col span={14} offset={5}>
          <Card title={compliancePageData.headerText} headStyle={{ fontSize: '1.25rem' }} className='ant-card-header-override'>
            <List
              dataSource={compliancePageData?.complianceItems}
              renderItem={item => (
                <>
                  <List.Item>
                    <Typography.Link style={{ fontSize: '1.1rem' }}>
                      <a href={item.url} target='_blank' rel='noreferrer'>
                        {item.text}
                      </a>
                    </Typography.Link>
                  </List.Item>
                </>
              )}
            />
          </Card>
        </Col>
      </Row>
    </ComplianceItemsContainer>
  )
}

export default ComplianceLandingSection
